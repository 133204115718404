<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-post-quiz-option',
  components: {
    BaseIcon,
  },
  props: {
    option: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    selectedOption: {
      type: String,
      default: null,
    },
    idx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    char() {
      return String.fromCharCode('A'.charCodeAt(0) + this.idx)
    },
  },
}
</script>

<template>
  <li
    class="relative my-[15px] flex min-h-16 items-center justify-between overflow-hidden rounded-xl px-6 py-[15px]"
    :class="{
      'cursor-pointer': !selectedOption,
      'bg-fg-success text-white':
        selectedOption === option.id && option.correct,
      'bg-fg-error text-white': selectedOption === option.id && !option.correct,
      'bg-divider-light text-default': selectedOption !== option.id,
    }"
    @click="() => $emit('click')"
  >
    <div v-if="char" class="flex">
      <b class="mr-2.5 block">{{ char }}.</b>
      <b class="grid grid-cols-1 break-words">
        {{ option.content }}
      </b>
    </div>
    <div class="ml-5 w-[30px]">
      <transition name="slide-y">
        <div v-if="selectedOption" class="flex items-center justify-center">
          <template v-if="selectedOption === option.id">
            <base-icon
              v-if="option.correct"
              svg="rec/check-circle"
              size="xl"
              class="text-white"
            />
            <base-icon
              v-if="!option.correct"
              svg="rec/error-circle"
              size="xl"
              class="text-white"
            />
          </template>
          <base-icon
            v-else-if="option.correct"
            svg="rec/check-mark"
            size="xl"
            class="text-fg-success"
          />
        </div>
      </transition>
    </div>
  </li>
</template>
